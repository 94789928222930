<template>
  <AuctionHeader :activeSearchDesktop="false" :activeSearchMobile="false" />
  <section class="bg-agrogo-green-2 pb-8">
    <div class="grid grid-cols-3 bg-agrogo-yellow-2 h-20 md:h-24">
      <div class="flex h-auto w-auto items-center justify-end">
        <span
          class="font-nexa font-black text-6xl md:text-7xl text-agrogo-yellow-2"
          >01</span
        >
      </div>
      <div
        class="col-span-2 flex flex-col items-start justify-center ml-5 md:ml-8"
      >
        <span class="font-nexa font-black text-base md:text-2xl mb-3 text-left"
          >Reglas Generales <span class="font-bold">de la Subasta</span>
        </span>
        <div class="flex items-center space-x-4 md:space-x-7 md:text-base">
          <span class="icon-punto text-agrogo-green-3"></span>
          <span class="icon-punto text-white"></span>
        </div>
      </div>
    </div>
    <div
      class="grid grid-cols-1 gap-4 md:gap-6 mx-8 md:mx-12 lg:mx-24 xl:mx-80 py-8 md:py-12 2xl:mx-96 text-left"
    >
      <div
        class="flex items-center font-dinpro font-normal text-white text-sm md:text-2xl"
      >
        <span class="icon-desplegar drop-down-icon"></span>Ac in elit rhoncus
        cras tempus aliquet mi diam.
      </div>
      <div
        class="flex items-center font-dinpro font-normal text-white text-sm md:text-2xl"
      >
        <span class="icon-desplegar drop-down-icon"></span>Ac in elit rhoncus
        cras tempus aliquet mi diam.
      </div>
      <div
        class="flex items-center font-dinpro font-normal text-white text-sm md:text-2xl"
      >
        <span class="icon-desplegar drop-down-icon"></span>Ac in elit rhoncus
        cras tempus aliquet mi diam.
      </div>
      <div
        class="flex items-center font-dinpro font-normal text-white text-sm md:text-2xl"
      >
        <span class="icon-desplegar drop-down-icon"></span>Ac in elit rhoncus
        cras tempus aliquet mi diam.
      </div>
      <div
        class="flex items-center font-dinpro font-normal text-white text-sm md:text-2xl"
      >
        <span class="icon-desplegar drop-down-icon"></span>Ac in elit rhoncus
        cras tempus aliquet mi diam.
      </div>
      <div
        class="flex items-center font-dinpro font-normal text-white text-sm md:text-2xl"
      >
        <span class="icon-desplegar drop-down-icon"></span>Ac in elit rhoncus
        cras tempus aliquet mi diam.
      </div>
    </div>
    <div class="bg-agrogo-yellow-2 h-0.5 mx-4 md:mx-12 lg:mx-24 xl:mx-80"></div>
  </section>
  <div class="bg-agrogo-yellow-1 h-14 md:h-20 flex items-center justify-center">
    <button @click="next()" type="button" class="btn-primary">
      Siguiente
      <span
        class="icon-desplegar flex items-center transform -rotate-90 text-agrogo-yellow-2 text-xs ml-1 leading-none"
      ></span>
    </button>
  </div>
</template>
<script>
import AuctionHeader from "@/components/header/HeaderAuction";
export default {
  components: {
    AuctionHeader,
  },
};
</script>
